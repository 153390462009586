import React from "react"

import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 35,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
}))

function PageTitle(props) {
  const classes = useStyles()

  return <Typography className={classes.title}>{props.children}</Typography>
}

export default PageTitle
