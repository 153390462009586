import c from "@redux/constants/confirmConstants"

export const confirmAction = (title, text, onSubmit) => ({
  type: c.CONFIRM,
  payload: { title, text, onSubmit },
})

export const closeConfirm = () => ({
  type: c.CLOSE_CONFIRM,
})
