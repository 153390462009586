export class LivesRequests {
  constructor(requestApi) {
    this.requestApi = requestApi
  }

  async createLive(body) {
    return this.requestApi({
      baseUrl: "lives",
      url: `/lives`,
      method: "post",
      body,
    })
  }

  async getLives() {
    return this.requestApi({
      baseUrl: "lives",
      url: `/lives`,
      method: "get",
    })
  }

  async getLive(id) {
    return this.requestApi({
      baseUrl: "lives",
      url: `/lives/${id}`,
      method: "get",
    })
  }

  async deleteLive(id) {
    return this.requestApi({
      baseUrl: "lives",
      url: `/lives/${id}`,
      method: "delete",
    })
  }
}
