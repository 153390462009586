import React from "react"

import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    marginTop: 24,
    textAlign: "center",
  },
}))

function ErrorText(props) {
  const classes = useStyles()

  return <Typography className={classes.error}>{props.children}</Typography>
}

export default ErrorText
