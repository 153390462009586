import React from "react"

import classNames from "classnames"

import { Grid, Switch, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "24px 0",
  },
  text: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  active: {
    color: theme.palette.secondary.main,
  },
}))

function CustomSwitch(props) {
  const classes = useStyles()

  const onChange = (e) => {
    props.onChange(e.target.checked)
  }

  const textClass = props.checked
    ? classNames(classes.text, classes.active)
    : classes.text

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Switch checked={props.checked} onChange={onChange} />
      <Typography className={textClass}>{props.text}</Typography>
    </Grid>
  )
}

export default CustomSwitch
