import React from "react"
import { useHistory } from "react-router-dom"

import classnames from "classnames"

import { IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ArrowBack } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 50,
    padding: 0,
    marginBottom: 24,
    [theme.breakpoints.down("xs")]: {
      marginTop: 24,
    },
  },

  icon: {
    padding: 8,
  },
}))

function BackButton(props) {
  const history = useHistory()

  const handleClick = () => {
    const { onClick } = props
    if (onClick) {
      onClick()
    } else {
      history.goBack()
    }
  }

  const classes = useStyles()
  const { dark, children, maxWidth, label, ...rest } = props
  return (
    <IconButton
      variant="text"
      classes={{
        root: classes.root,
        label: dark
          ? classnames(classes.label, classes.labelDark)
          : classes.label,
      }}
      onClick={handleClick}
      {...rest}
    >
      <ArrowBack className={classes.icon} />
    </IconButton>
  )
}

export default BackButton
