import React from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"

import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import EndMenuItem from "./EndMenuItem"
import MenuItem from "./MenuItem"

import getMenuItems from "@api/leftMenu/getMenuItems"

// import logo from "@static/images/logo.png"

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100vh",
      width: 240,
      backgroundColor: theme.palette.background.darkBackground,
      overflow: "hidden",
    },
    logo: {
      height: 70,
      margin: 16,
      marginBottom: 48,
    },
    menuItems: {
      flex: 1,
      width: "100%",
    },
    endMenuItems: {
      width: "100%",
      // display: "flex",
      alignItems: "flex-end",
      paddingBottom: 24,
    },
    title: {
      fontSize: 30,
      textAlign: "center",
      margin: 16,
      marginBottom: 48,
      color: theme.palette.background.contrast,
      fontWeight: 700,
    },
  }
})

function LeftMenu(props) {
  const classes = useStyles()

  const location = useLocation()
  const active = "/" + location.pathname.split("/")[2]

  const history = useHistory()
  const match = useRouteMatch()
  const { menuItems, endMenuItems } = getMenuItems(history, match.url)

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      alignItems="center"
    >
      <Grid>
        {/* <img src={logo} className={classes.logo} alt="logo" /> */}
        <Typography className={classes.title}>GoStream</Typography>
      </Grid>
      <Grid className={classes.menuItems}>
        {menuItems.map((item, index) => (
          <MenuItem item={item} key={`menu-item-${index}`} active={active} />
        ))}
      </Grid>
      <Grid className={classes.endMenuItems}>
        {endMenuItems.map((item, index) => (
          <EndMenuItem item={item} key={`end-menu-item-${index}`} />
        ))}
      </Grid>
    </Grid>
  )
}

export default LeftMenu
