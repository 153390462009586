import React from "react"
import { Controller } from "react-hook-form"

import classnames from "classnames"

import { Grid, TextField, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  input: {
    height: 48,
    fontSize: 16,
    marginTop: 12,
    marginBottom: 12,
    width: "100%",
    borderRadius: 6,
  },
  errorMessage: {
    fontSize: 13,
    width: "100%",
    marginLeft: 8,
    marginTop: 4,
    color: theme.palette.error.main,
  },
}))

function Input(props) {
  const classes = useStyles()

  const isWithError = () => {
    const { name, errors, error } = props
    if ((errors && errors[name]) || error) {
      return true
    }
    return false
  }

  const getErrorMessage = () => {
    const { errors, name } = props
    return errors[name]?.message
  }

  const {
    inputComponent,
    name,
    error,
    className,
    control,
    onChange: handleChange,
    ...rest
  } = props

  const errorMessage = getErrorMessage()

  return (
    <Grid container justifyContent="center">
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            className={classnames(className, classes.input)}
            variant="outlined"
            error={isWithError()}
            onChange={(...params) => {
              field.onChange(...params)
              if (handleChange) {
                handleChange(...params)
              }
            }}
            value={field.value || ""}
            InputProps={{
              inputComponent,
            }}
            {...rest}
          />
        )}
      />

      {errorMessage ? (
        <Typography className={classes.errorMessage}>{errorMessage}</Typography>
      ) : null}
    </Grid>
  )
}

export default Input
