import React from "react"
import { Controller } from "react-hook-form"

import ChipInput from "material-ui-chip-input"

import { Grid, InputLabel, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  input: {
    minHeight: 48,
    fontSize: 16,
    marginTop: 12,
    marginBottom: 12,
    width: "100%",
    borderRadius: 6,
  },
  errorMessage: {
    fontSize: 13,
    width: "100%",
    marginLeft: 8,
    marginTop: 4,
    color: theme.palette.error.main,
  },
}))

function Tags({
  label = "Tags",
  name,
  control,
  onChange,
  error,
  errors,
  ...rest
}) {
  const classes = useStyles()

  const isWithError = () => {
    if ((errors && errors[name]) || error) {
      return true
    }
    return false
  }

  const getErrorMessage = () => {
    return errors[name]?.message
  }

  const errorMessage = getErrorMessage()

  return (
    <Grid container style={{ marginTop: 10 }}>
      <InputLabel>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <ChipInput
            {...field}
            className={classes.input}
            fullWidth
            variant="outlined"
            error={isWithError()}
            placeholder="Press Enter to add tag"
            value={field.value || []}
            onDelete={(...params) => {
              const [, index] = params

              const newValue = field.value.slice()
              newValue.splice(index, 1)

              field.onChange(newValue)
              if (onChange) {
                onChange(newValue)
              }
            }}
            onChange={(...params) => {
              const array = params[0]
              const last = array.length && array[array.length - 1]

              const newValue = field.value ? field.value.slice() : []
              newValue.push(last)

              field.onChange(newValue)
              if (onChange) {
                onChange(newValue)
              }
            }}
            {...rest}
          />
        )}
      />

      {errorMessage ? (
        <Typography className={classes.errorMessage}>{errorMessage}</Typography>
      ) : null}
    </Grid>
  )
}

export default Tags
