import React from "react"

import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    fontSize: 15,
    textAlign: "center",
    margin: "8px 0",
  },
}))

function Error(props) {
  const classes = useStyles()

  return <Typography className={classes.error}>{props.text}</Typography>
}

export default Error
