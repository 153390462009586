import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import PrimaryButton from "@components/UI/Button/PrimaryButton"
import Input from "@components/UI/Input/Input"

import Error from "./Error"
import Subtitle from "./Subtitle"
import Title from "./Title"

import getUrlParams from "@api/utils/getUrlParams"
import forgotPasswordResolver from "@api/validation/auth/forgotPasswordResolver"

import { forgotPassword } from "@redux/actions/authActions"

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 16,
  },
  forgotButton: {
    marginTop: 8,
  },
  leftInput: {
    [theme.breakpoints.up("sm")]: {
      marginRight: 16,
    },
  },
}))

function ForgotPasswordForm(props) {
  const [error, setError] = useState("")
  const [emailSended, setEmailSended] = useState(false)

  const onSendSuccess = () => {
    setEmailSended(true)
  }

  const onSendError = (error) => {
    setError(error)
  }

  const history = useHistory()

  const goToLogin = () => {
    history.replace("/login")
  }

  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    setError("")
    dispatch(forgotPassword(data, onSendSuccess, onSendError))
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: forgotPasswordResolver,
  })

  useEffect(() => {
    const params = getUrlParams()
    const defaultEmail = params?.email
    defaultEmail && setValue("email", defaultEmail)
  }, [setValue])

  const { isLoadingForgotPassword } = useSelector((state) => state.get("auth"))

  const email = getValues("email")
  const classes = useStyles()

  return (
    <Grid>
      <Title>Esqueci minha Senha</Title>
      {!emailSended ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Subtitle>
            To recover your password we will send you and confirmation email.
            Please, fill in the field below.
          </Subtitle>

          <Input name="email" label="Email" errors={errors} control={control} />

          {error ? <Error text={error} /> : null}
          <PrimaryButton
            className={classes.button}
            type="submit"
            isLoading={isLoadingForgotPassword}
          >
            Send Email
          </PrimaryButton>
        </form>
      ) : (
        <Grid>
          <Subtitle>
            {`Foi enviado um e-mail para ${email} com as instruções para redefinir
            sua senha.`}
          </Subtitle>
          <PrimaryButton className={classes.button} onClick={goToLogin}>
            Go back to Login
          </PrimaryButton>
        </Grid>
      )}
    </Grid>
  )
}

export default ForgotPasswordForm
