import React from "react"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Pagination } from "@material-ui/lab"

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    marginTop: 48,
  },
}))

function CustomPagination(props) {
  const classes = useStyles()

  const onChange = (_, page) => props.onChange(page)

  return (
    <Grid
      container
      justifyContent="center"
      className={classes.paginationContainer}
    >
      <Pagination
        color="primary"
        count={props.pageCount}
        onChange={onChange}
        page={props.page}
      />
    </Grid>
  )
}

export default CustomPagination
