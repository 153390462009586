import React from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import Button from "@components/UI/Button/Button"
import PrimaryButton from "@components/UI/Button/PrimaryButton"

import { closeConfirm } from "@redux/actions/confirmActions"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
  },
  text: {
    color: theme.palette.text.secondary,
  },
  actions: {
    paddingRight: 24,
  },
  column: {
    marginRight: 24,
  },
}))

function Confirm(props) {
  const classes = useStyles()

  const { open, title, text, onSubmit } = useSelector((state) =>
    state.get("confirm")
  )

  const dispatch = useDispatch()

  const handleSubmit = () => {
    if (onSubmit) onSubmit()
    dispatch(closeConfirm())
  }

  const handleCancel = () => {
    dispatch(closeConfirm())
  }

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogContent className={classes.root}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.text}>{text}</Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item className={classes.column}>
            <Button onClick={handleCancel}>Cancel</Button>
          </Grid>
          <Grid item>
            <PrimaryButton onClick={handleSubmit} paddingHorizontal={16}>
              Confirm
            </PrimaryButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default Confirm
