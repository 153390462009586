import React, { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"

import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import Player from "@components/Player"
import SimpleTable from "@components/Table/SimpleTable"
import BackButton from "@components/UI/Button/BackButton"
import DangerButton from "@components/UI/Button/DangerButton"
import Loading from "@components/UI/Loading"
import PageTitle from "@components/UI/Text/PageTitle"

import formatTableData from "@api/format/formatTableData"
import tableHeader from "@api/lives/tableHeader"
import { RequestSDK } from "@api/request"

const useStyles = makeStyles((theme) => ({
  titleSection: {
    marginTop: 48,
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 24,
    color: theme.palette.text.primary,
  },
  notFound: {
    fontSize: 20,
    color: theme.palette.text.secondary,
    marginTop: 48,
    textAlign: "center",
  },
  thumbnail: {
    width: "100%",
  },
}))

function Live(props) {
  const [live, setLive] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  const classes = useStyles()

  const { id } = useParams()

  useEffect(() => {
    const fetchLive = async () => {
      try {
        setIsFetching(true)
        const { data } = await RequestSDK.lives.getLive(id)
        setLive(data)
      } catch (err) {
        console.log(err)
      } finally {
        setIsFetching(false)
      }
    }

    fetchLive()
  }, [id])

  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  const handleDelete = async () => {
    await RequestSDK.lives.deleteLive(live._id)
    goBack()
  }

  return (
    <Grid>
      <BackButton />
      <PageTitle>Live</PageTitle>

      {isFetching ? (
        <Loading />
      ) : live ? (
        <>
          <Grid container justifyContent="flex-end">
            <DangerButton
              onClick={handleDelete}
              alertTitle="Delete Live"
              alertText="Are you sure you want to delete the live? It will not be possible to recover the information and files."
            >
              Delete Live
            </DangerButton>
          </Grid>
          <Grid container>
            <Grid
              container
              alignItems="center"
              className={classes.titleSection}
            >
              <Typography className={classes.sectionTitle}>Metadata</Typography>
            </Grid>

            <SimpleTable
              header={tableHeader}
              data={formatTableData([live])}
              onClickRow={null}
            />
          </Grid>

          <Grid container>
            <Grid
              container
              alignItems="center"
              className={classes.titleSection}
            >
              <Typography className={classes.sectionTitle}>
                Stream Information
              </Typography>
            </Grid>
            <Grid container direction="column">
              <Typography>
                <b>Stream URL: </b>
                {process.env.REACT_APP_STREAM_URL}
              </Typography>
              <Typography>
                <b>Secret Key: </b>
                {live._id}
              </Typography>
            </Grid>
          </Grid>

          {live.status === "STARTED" && (
            <Grid container>
              <Grid
                container
                alignItems="center"
                className={classes.titleSection}
              >
                <Typography className={classes.sectionTitle}>
                  Live Preview
                </Typography>
              </Grid>

              <Player
                url={`https://stream.gostream.com.br/${id}.m3u8?type=live`}
              />
            </Grid>
          )}
        </>
      ) : (
        <Grid container justifyContent="center">
          <Typography className={classes.notFound}>Live not found</Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default Live
