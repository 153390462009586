import React, { useState } from "react"

import classNames from "classnames"

import { Grid, Input, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Search } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "48px 0",
  },
  textField: {
    height: 48,
    fontSize: 16,
    width: 300,
    borderRadius: 6,
    borderColor: theme.palette.action.disabled,
    borderWidth: 1,
    borderStyle: "solid",
    paddingLeft: 8,
  },
  active: {
    borderColor: theme.palette.secondary.main,
    borderWidth: 2,
  },
}))

function SearchInput({ onSearch, ...props }) {
  const [value, setValue] = useState("")
  const classes = useStyles()

  const handleSubmit = (e) => {
    e?.preventDefault()
    if (value) onSearch(value)
  }

  const onChange = (e) => {
    setValue(e.target.value)
    if (value && !e.target.value) {
      onSearch("")
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center" className={classes.root}>
        <Input
          {...props}
          value={value}
          onChange={onChange}
          className={
            !!value
              ? classNames(classes.textField, classes.active)
              : classes.textField
          }
          disableUnderline
          inputProps={{
            style: {
              height: 15,
            },
          }}
          endAdornment={
            <IconButton onClick={handleSubmit}>
              <Search />
            </IconButton>
          }
        />
      </Grid>
    </form>
  )
}

export default SearchInput
