import React from "react"

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  row: {
    height: 80,
    cursor: "pointer",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))

function SimpleTable(props) {
  const getHeaders = () => {
    const { header } = props

    if (header) {
      return Object.values(header)
    }
    return []
  }

  const getItemColumns = (item) => {
    const { header } = props
    if (header && item) {
      const headers = Object.keys(header)
      const columns = headers.map((h) => item[h])
      return columns
    }
    return []
  }

  const classes = useStyles()

  const { data } = props
  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {getHeaders().map((label, index) => (
              <TableCell key={`header-item-${index}`}>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow
              key={item._id}
              className={classes.row}
              onClick={() => props.onClickRow && props.onClickRow(item._id)}
            >
              {getItemColumns(item).map((column, index) => (
                <TableCell key={`table-item-${index}`}>{column}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SimpleTable
