import React, { useEffect, useState } from "react"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import CreateLiveButton from "@components/Lives/CreateLiveButton"
import LivesList from "@components/Lives/LivesList"
import PageTitle from "@components/UI/Text/PageTitle"

import { RequestSDK } from "@api/request"

const useStyles = makeStyles((theme) => ({
  root: {},
}))

function Lives(props) {
  const [lives, setLives] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const fetchLives = async () => {
    setIsFetching(true)
    try {
      const { data } = await RequestSDK.lives.getLives()
      setLives(data)
    } catch (err) {
      console.log(err)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    fetchLives()
  }, [])

  const classes = useStyles()

  return (
    <Grid className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <PageTitle>Lives</PageTitle>
        </Grid>
        <Grid item>
          <CreateLiveButton onCreateSuccess={() => fetchLives()} />
        </Grid>
      </Grid>
      <LivesList lives={lives} isFetching={isFetching} />
    </Grid>
  )
}

export default Lives
