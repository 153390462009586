import axios from "axios"

const baseUrls = {
  default: process.env.REACT_APP_API_VIDEOS,
  lives: process.env.REACT_APP_API_LIVES,
}

const errors = {
  domainError: "Domain is required",
  urlError: "Url is required",
}

const verifyErrors = ({ url, method }) => {
  if (!url) throw new Error(errors.urlError)
}

const api = (content) => {
  const {
    url,
    method = "get",
    headers: hr,
    params: pr,
    body,
    token,
    onUploadProgress,
    baseUrl,
  } = content

  const baseURL = baseUrl ? baseUrls[baseUrl] : baseUrls["default"]

  try {
    verifyErrors(content)

    let jwt = ""
    if (process.browser) {
      jwt = "JWT " + localStorage.getItem("access_token")
    } else if (token) {
      jwt = "JWT " + token
    }

    const headers = {
      Authorization: jwt,
      "Content-Type": "application/json",
      ...hr,
    }

    const params = pr || {}

    return axios({
      method,
      baseURL,
      url,
      headers,
      params,
      data: body || undefined,
      onUploadProgress,
    })
  } catch (err) {
    return err
  }
}

export default api
