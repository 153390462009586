import { createTheme } from "@material-ui/core/styles"

import themePalette from "./palette"

const theme = createTheme({
  palette: {
    primary: {
      main: themePalette.primaryMain,
      dark: themePalette.primaryDark,
      light: themePalette.primaryLight,
    },
    secondary: {
      main: themePalette.secondaryMain,
      dark: themePalette.secondaryDark,
      light: themePalette.secondaryLight,
    },
    background: {
      darkBackground: themePalette.darkBackground,
      black: themePalette.black,
      contrast: themePalette.textContrast,
    },
    text: {
      contrastText: themePalette.textContrast,
    },
  },
  typography: {
    fontFamily: "Raleway",
  },
})

export default theme
