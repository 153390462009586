import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useHistory } from "react-router-dom"

import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import SimpleTable from "@components/Table/SimpleTable"
import BackButton from "@components/UI/Button/BackButton"
import DangerButton from "@components/UI/Button/DangerButton"
import Loading from "@components/UI/Loading"
import PageTitle from "@components/UI/Text/PageTitle"
import EditMetadataButton from "@components/Videos/EditMetadataButton"
import EditVideoFileButton from "@components/Videos/EditVideoFileButton"
import GetVideoUrl from "@components/Videos/GetVideoUrl"

import formatTableData from "@api/format/formatTableData"
import tableHeader from "@api/videos/tableHeader"

import { getVideo, deleteVideo } from "@redux/actions/videosActions"

const useStyles = makeStyles((theme) => ({
  titleSection: {
    marginTop: 48,
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 24,
    color: theme.palette.text.primary,
  },
  notFound: {
    fontSize: 20,
    color: theme.palette.text.secondary,
    marginTop: 48,
    textAlign: "center",
  },
  thumbnail: {
    width: "100%",
  },
}))

function Video(props) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const { id } = useParams()

  useEffect(() => {
    dispatch(getVideo(id))
  }, [dispatch, id])

  const { isLoadingVideo, video } = useSelector((state) => state.get("videos"))

  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  const handleDelete = () => {
    dispatch(deleteVideo(video._id, goBack))
  }

  return (
    <Grid>
      <BackButton />
      <PageTitle>Video</PageTitle>

      {isLoadingVideo ? (
        <Loading />
      ) : video ? (
        <>
          <Grid container justifyContent="flex-end">
            <DangerButton
              onClick={handleDelete}
              alertTitle="Delete Video"
              alertText="Are you sure you want to delete the video? It will not be possible to recover the information and files."
            >
              Delete Video
            </DangerButton>
          </Grid>
          <Grid container>
            <Grid
              container
              alignItems="center"
              className={classes.titleSection}
            >
              <Typography className={classes.sectionTitle}>Metadata</Typography>
              <Grid item>
                <EditMetadataButton />
              </Grid>
            </Grid>

            <SimpleTable
              header={tableHeader}
              data={formatTableData([video])}
              onClickRow={null}
            />
          </Grid>

          <>
            <Grid container>
              <Grid
                container
                alignItems="center"
                className={classes.titleSection}
              >
                <Typography className={classes.sectionTitle}>
                  Get Video URL
                </Typography>
                <EditVideoFileButton />
              </Grid>
              <GetVideoUrl />
            </Grid>
            <Grid container>
              <Grid
                container
                alignItems="center"
                className={classes.titleSection}
              >
                <Typography className={classes.sectionTitle}>
                  Thumbnail
                </Typography>
              </Grid>
              <Grid container>
                <Grid item xs={11} sm={6} md={4}>
                  <img
                    src={video.thumbnail}
                    className={classes.thumbnail}
                    alt="thumbnail"
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        </>
      ) : (
        <Grid container justifyContent="center">
          <Typography className={classes.notFound}>Video not found</Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default Video
