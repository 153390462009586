import React from "react"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import logo from "@static/images/logo.png"

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "50%",
    marginBottom: 16,
  },
}))

function Logo(props) {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center">
      <img src={logo} className={classes.logo} alt="logo" />
    </Grid>
  )
}

export default Logo
