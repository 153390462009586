import React from "react"

import classNames from "classnames"

import { Button, CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 6,
    padding: 0,
    "&.Mui-disabled": {
      backgroundColor: theme.palette.action.disabled,
    },
  },
  label: {
    color: theme.palette.primary.main,
    padding: 6,
    fontSize: 13,
  },
  labelDisabled: {
    color: theme.palette.primary.main,
    padding: 6,
    fontSize: 13,
  },
}))

function CustomButton(props) {
  const classes = useStyles()

  const {
    isLoading,
    disabled,
    children,
    maxWidth,
    paddingHorizontal,
    className,
    width,
    ...rest
  } = props

  const isDisabled = isLoading || disabled

  return (
    <Button
      {...rest}
      classes={{
        root: classNames(classes.root, className || null),
        label: isDisabled ? classes.labelDisabled : classes.label,
      }}
      style={{
        maxWidth: maxWidth || "100%",
        width: width,
        padding: paddingHorizontal && `0 ${paddingHorizontal}px`,
      }}
      disabled={isDisabled}
    >
      {isLoading ? <CircularProgress size={24} /> : children}
    </Button>
  )
}

export default CustomButton
