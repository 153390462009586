import React from "react"
import { useSelector, useDispatch } from "react-redux"

import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"

import { closeAlert } from "@redux/actions/alertActions"

const MyAlert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function DefaultSnackbar(props) {
  const dispatch = useDispatch()

  const handleCloseSnack = () => {
    dispatch(closeAlert())
  }

  const { open, type, message } = useSelector((state) => state.get("alert"))

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseSnack}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MyAlert onClose={handleCloseSnack} severity={type || "success"}>
        {message}
      </MyAlert>
    </Snackbar>
  )
}

export default DefaultSnackbar
