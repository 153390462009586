import * as yup from "yup"

export const validateEmail = () =>
  yup.string().email("Invalid email format").required("Email is required")

export const validatePassword = () =>
  yup
    .string()
    .min(6, "Password must be at least 6 digits long")
    .required("Password is required")

export const validateConfirmPassword = (passwordName) =>
  yup
    .string()
    .oneOf([yup.ref(passwordName)], "Passwords are different")
    .required("Confirmation is required")
