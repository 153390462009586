import React from "react"
import { Route, Redirect, Switch } from "react-router-dom"

import { Grid } from "@material-ui/core"

import Confirm from "@components/UI/Confirm"
import Snackbar from "@components/UI/Snackbar/Snackbar"

import Dashboard from "@pages/Dashboard"
import ForgotPassword from "@pages/ForgotPassword"
import Login from "@pages/Login"
import RecoverPassword from "@pages/RecoverPassword"
import Register from "@pages/Register"

function RoutesGeneral() {
  return (
    <Grid>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/recover-password" component={RecoverPassword} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route path="/app" component={Dashboard} />
        <Redirect to="/app" />
      </Switch>
      <Snackbar />
      <Confirm />
    </Grid>
  )
}

export default RoutesGeneral
