import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"

import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import CustomPagination from "@components/Table/Pagination"
import SimpleTable from "@components/Table/SimpleTable"
import SearchInput from "@components/UI/Input/SearchInput"
import Loading from "@components/UI/Loading"

import formatTableData from "@api/format/formatTableData"
import tableHeader from "@api/videos/tableHeader"

import { getVideos } from "@redux/actions/videosActions"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 48,
    minHeight: "100vh",
  },
  notFound: {
    color: theme.palette.text.secondary,
    margin: 36,
    textAlign: "center,",
  },
}))

function VideosList(props) {
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getVideos(1))
  }, [dispatch])

  const classes = useStyles()

  const history = useHistory()
  const match = useRouteMatch()

  const onClickVideo = (id) => {
    history.push(`${match.url}/${id}`)
  }

  const fetchVideos = (p, f) => {
    dispatch(getVideos(p, f))
  }

  const onChangePage = (p) => {
    setPage(p)
    fetchVideos(p, filter)
  }

  const changeFilter = (f) => {
    setFilter(f)
    setPage(1)
    fetchVideos(1, f)
  }

  const { videos, isLoadingVideos, videosPageCount } = useSelector((state) =>
    state.get("videos")
  )

  return (
    <Grid className={classes.root}>
      <SearchInput onSearch={changeFilter} placeholder="Search for Tags" />
      {isLoadingVideos ? (
        <Loading />
      ) : videos && videos.length ? (
        <>
          <SimpleTable
            header={tableHeader}
            data={formatTableData(videos)}
            onClickRow={onClickVideo}
          />
          <CustomPagination
            pageCount={videosPageCount}
            onChange={onChangePage}
            page={page}
          />
        </>
      ) : (
        <Grid container justifyContent="center">
          <Typography className={classes.notFound}>
            No video found with this query
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default VideosList
