import React from "react"
import ReactHlsPlayer from "react-hls-player"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({}))

function Player(props) {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid item xs={11} sm={6} md={5}>
        <ReactHlsPlayer
          src={props.url}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        />
      </Grid>
    </Grid>
  )
}

export default Player
