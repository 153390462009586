import { yupResolver } from "@hookform/resolvers/yup"

import * as yup from "yup"

import { validateEmail, validatePassword, validateConfirmPassword } from "."

const schema = yup.object().shape({
  email: validateEmail(),
  password: validatePassword(),
  confirmPassword: validateConfirmPassword("password"),
})

export default yupResolver(schema)
