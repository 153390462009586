import React from "react"

import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: 17,
    textAlign: "center",
    margin: "16px 0",
  },
}))

function Subtitle(props) {
  const classes = useStyles()

  return <Typography className={classes.subtitle}>{props.children}</Typography>
}

export default Subtitle
