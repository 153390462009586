/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from "redux-immutable"

// Global Reducers
import alert from "./reducers/alertReducer"
import auth from "./reducers/authReducers"
import confirm from "./reducers/confirmReducers"
import videos from "./reducers/videosReducers"

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    auth,
    alert,
    videos,
    confirm,
    ...injectedReducers,
  })

  // Wrap the root reducer and return a new root reducer with router state
  return rootReducer
}
