const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  RECOVER_PASSWORD_REQUEST: "RECOVER_PASSWORD_REQUEST",
  RECOVER_PASSWORD_SUCCESS: "RECOVER_PASSWORD_SUCCESS",
  RECOVER_PASSWORD_FAILURE: "RECOVER_PASSWORD_FAILURE",

  LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
}

export default authConstants
