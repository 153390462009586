import c from "@redux/constants/authConstants"

const initialState = {
  error: false,
  isAuthenticated:
    process.browser && (localStorage.getItem("access_token") ? true : false),

  isLoadingLogin: false,
  isLoadingRegister: false,
  isLoadingForgotPassword: false,
  isLoadingRecoverPassword: false,
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case c.LOGIN_REQUEST:
      return {
        ...state,
        isLoadingLogin: true,
        isAuthenticated: false,
      }

    case c.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoadingLogin: false,
        isAuthenticated: true,
        error: false,
      }
    }

    case c.LOGIN_FAILURE: {
      return {
        ...state,
        isLoadingLogin: false,
        isAuthenticated: false,
        error: action.error,
      }
    }

    case c.REGISTER_REQUEST:
      return {
        ...state,
        isLoadingRegister: true,
        isAuthenticated: false,
      }

    case c.REGISTER_SUCCESS: {
      return {
        ...state,
        isLoadingRegister: false,
        isAuthenticated: true,
        error: false,
      }
    }

    case c.REGISTER_FAILURE: {
      return {
        ...state,
        isLoadingRegister: false,
        isAuthenticated: false,
        error: action.error,
      }
    }

    case c.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoadingForgotPassword: true,
      }

    case c.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoadingForgotPassword: false,
        error: false,
      }
    }

    case c.FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        isLoadingForgotPassword: false,
        error: action.error,
      }
    }

    case c.RECOVER_PASSWORD_REQUEST:
      return {
        ...state,
        isLoadingRecoverPassword: true,
      }

    case c.RECOVER_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoadingRecoverPassword: false,
        error: false,
      }
    }

    case c.RECOVER_PASSWORD_FAILURE: {
      return {
        ...state,
        isLoadingRecoverPassword: false,
        error: action.error,
      }
    }

    case c.LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoadingLogin: false,
        isAuthenticated: false,
        user: "",
      }
    }

    default:
      return state
  }
}
