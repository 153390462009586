import { omit } from "lodash"

const formatVideoCreationBody = (body, withLink) => {
  let result = body
  if (!withLink) {
    result = omit(body, ["input"])
  }
  return result
}

export default formatVideoCreationBody
