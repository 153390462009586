import c from "@redux/constants/alertConstants"

const initialState = {
  open: false,
  type: "",
  message: "",
}

export default function alert(state = initialState, action) {
  switch (action.type) {
    case c.OPEN_ALERT: {
      return {
        ...state,
        open: true,
        type: action.payload.type,
        message: action.payload.message,
      }
    }
    case c.CLOSE_ALERT: {
      return {
        ...state,
        open: false,
        type: "",
        message: "",
      }
    }

    default:
      return state
  }
}
