import { yupResolver } from "@hookform/resolvers/yup"

import * as yup from "yup"

import { validateInput } from "."

const schema = yup.object().shape({
  input: validateInput(),
})

export default yupResolver(schema)
