import { yupResolver } from "@hookform/resolvers/yup"

import * as yup from "yup"

import { validatePassword, validateConfirmPassword } from "./"

const schema = yup.object().shape({
  newPassword: validatePassword(),
  confirmPassword: validateConfirmPassword("newPassword"),
})

export default yupResolver(schema)
