import React from "react"

import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 2,
    borderRadius: 16,
    backgroundColor: theme.palette.secondary.main,
  },
  text: {
    color: theme.palette.text.contrastText,
    fontSize: 13,
    padding: 6,
  },
}))

function Tags(props) {
  const classes = useStyles()

  return (
    <Grid container>
      {props.tags.map((t, index) => (
        <Grid key={`tag-${index}`} className={classes.root}>
          <Typography className={classes.text}>{t}</Typography>
        </Grid>
      ))}
    </Grid>
  )
}

export default Tags
