const videosConstants = {
  GET_VIDEOS_REQUEST: "GET_VIDEOS_REQUEST",
  GET_VIDEOS_SUCCESS: "GET_VIDEOS_SUCCESS",
  GET_VIDEOS_FAILURE: "GET_VIDEOS_FAILURE",

  GET_VIDEO_REQUEST: "GET_VIDEO_REQUEST",
  GET_VIDEO_SUCCESS: "GET_VIDEO_SUCCESS",
  GET_VIDEO_FAILURE: "GET_VIDEO_FAILURE",

  GET_VIDEO_URL_SUCCESS: "GET_VIDEO_URL_SUCCESS",

  CREATE_VIDEO_REQUEST: "CREATE_VIDEO_REQUEST",
  CREATE_VIDEO_SUCCESS: "CREATE_VIDEO_SUCCESS",
  CREATE_VIDEO_FAILURE: "CREATE_VIDEO_FAILURE",
}

export default videosConstants
