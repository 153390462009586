const getUrlParams = () => {
  if (typeof window !== "undefined") {
    const search = decodeURIComponent(window.location.search)
    if (search) {
      const query = search.split("?")[1]
      const response = {}
      query.split("&").forEach((q) => {
        const split = q.split("=")
        if (split.length === 2) {
          response[split[0]] = split[1]
        }
      })
      return response
    }
  }
  return undefined
}

export default getUrlParams
