import React from "react"

import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: 22,
    textAlign: "center",
    margin: "16px 0",
  },
}))

function Title(props) {
  const classes = useStyles()

  return <Typography className={classes.title}>{props.children}</Typography>
}

export default Title
