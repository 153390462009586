import React from "react"

import { Divider, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  divider: {
    height: 1,
    backgroundColor: theme.palette.text.primary,
    width: "100%",
  },
  overText: {
    position: "absolute",
    backgroundColor: theme.palette.background.default,
    padding: 5,
    color: theme.palette.text.primary,
    fontSize: 13,
  },
  dividerContainer: {
    position: "relative",
    margin: "24px 0",
  },
}))

function DividerWithText(props) {
  const classes = useStyles()
  const { text } = props

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.dividerContainer}
    >
      <Divider className={classes.divider} />
      <Typography className={classes.overText}>{text}</Typography>
    </Grid>
  )
}

export default DividerWithText
