import React from "react"
import { useHistory } from "react-router-dom"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import Logo from "@components/Auth/Logo"
import RegisterForm from "@components/Auth/RegisterForm"
import BackButton from "@components/UI/Button/BackButton"
import SecondaryButton from "@components/UI/Button/SecondaryButton"
import DividerWithText from "@components/UI/Text/DividerWithText"

import useRedirectIfAuthenticated from "@hooks/auth/useRedirectIfAuthenticated"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    maxWidth: 1920,
  },
  button: {
    marginTop: 16,
  },
  content: {
    padding: "16px 0",
  },
}))

function Register(props) {
  useRedirectIfAuthenticated()

  const history = useHistory()

  const goToLogin = () => {
    history.replace("/login")
  }

  const classes = useStyles()

  return (
    <Grid container justifyContent="center">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={11} sm={4} className={classes.content}>
          <BackButton label="Login" onClick={goToLogin} />
          <Logo />
          <RegisterForm />
          <DividerWithText text="Already have an account?" />
          <SecondaryButton className={classes.button} onClick={goToLogin}>
            Go to Login
          </SecondaryButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Register
