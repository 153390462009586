import { yupResolver } from "@hookform/resolvers/yup"

import * as yup from "yup"

import { validateTags, validateVisibility } from "."

const schema = yup.object().shape({
  tags: validateTags(),
  visibility: validateVisibility(),
})

export default yupResolver(schema)
