import { format } from "date-fns"

const formatDate = (d, f) => {
  let date = d
  if (date) {
    if (typeof date !== Date) {
      date = new Date(d)
    }
    return format(date, f)
  }
  return ""
}

export default formatDate
