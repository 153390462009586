import React from "react"
import { useHistory, useRouteMatch } from "react-router-dom"

import classNames from "classnames"

import { Button, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    width: "100%",
    transition: "all 0.3s",
    textTransform: "none",
    borderRadius: 0,
    position: "relative",
    "&:hover": {
      backgroundColor: theme.palette.background.black,
      "& $label": {
        opacity: 1,
      },
    },
  },
  label: {
    color: theme.palette.background.contrast,
    opacity: 0.7,
    fontSize: 17,
    transition: "all 0.3s",
    fontWeight: 500,
  },
  active: {
    color: theme.palette.primary.main,
  },
}))

function MenuItem(props) {
  const classes = useStyles(props.item)

  const history = useHistory()
  const match = useRouteMatch()

  const goToPath = () => {
    history.push(`${match.url}${props.item.path}`)
  }

  const isActive = () => {
    return props.active === props.item.path
  }

  const labelClassName = isActive()
    ? classNames(classes.label, classes.active)
    : classes.label

  return (
    <Button
      className={classes.root}
      onClick={goToPath}
      disabled={props.item?.disabled}
    >
      <Typography className={labelClassName}>{props.item.label}</Typography>
    </Button>
  )
}

export default MenuItem
