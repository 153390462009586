import React from "react"
import ReactDOM from "react-dom"
//Redux Store
import { Provider } from "react-redux"

//Theme
import { ThemeProvider } from "@material-ui/core/styles"

import App from "./App"

import store from "@redux/configureStore"

import theme from "@styles/theme/theme"

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)
