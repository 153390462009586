import c from "@redux/constants/confirmConstants"

const initialState = {
  open: false,
  title: "",
  text: "",
  onSubmit: null,
}

export default function confirm(state = initialState, action) {
  switch (action.type) {
    case c.CONFIRM: {
      return {
        ...state,
        open: true,
        title: action.payload.title,
        text: action.payload.text,
        onSubmit: action.payload.onSubmit,
      }
    }
    case c.CLOSE_CONFIRM: {
      return {
        ...state,
        open: false,
        title: "",
        text: "",
        onSubmit: null,
      }
    }

    default:
      return state
  }
}
