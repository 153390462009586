import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import PrimaryButton from "@components/UI/Button/PrimaryButton"
import Input from "@components/UI/Input/Input"

import Error from "./Error"
import Subtitle from "./Subtitle"
import Title from "./Title"

import getUrlParams from "@api/utils/getUrlParams"
import recoverPasswordResolver from "@api/validation/auth/recoverPasswordResolver"

import { recoverPassword } from "@redux/actions/authActions"

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 16,
  },
  forgotButton: {
    marginTop: 8,
  },
  title: {
    fontSize: 17,
    margin: "16px 0",
    color: theme.palette.primary.main,
    textAlign: "center",
    fontWeight: "bold",
  },
  subtitle: {
    color: theme.palette.text.primary,
    textAlign: "center",
    margin: "16px 0",
  },
}))

function RecoverPasswordForm(props) {
  const [error, setError] = useState("")
  const [emailSended, setEmailSended] = useState(false)

  const onSendSuccess = () => {
    setEmailSended(true)
  }

  const onSendError = (error) => {
    setError(error)
  }

  const history = useHistory()

  const goToLogin = () => {
    history.replace("/login")
  }

  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    setError("")

    const params = getUrlParams()
    if (!params || !params.email || !params.token) {
      setError(
        "It was not possible to recover your password, please click again on the link sent in your email"
      )
    } else {
      const body = { ...data, email: params.email, token: params.token }
      dispatch(recoverPassword(body, onSendSuccess, onSendError))
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: recoverPasswordResolver,
  })

  useEffect(() => {
    const params = getUrlParams()
    const defaultEmail = params?.email
    defaultEmail && setValue("email", defaultEmail)
  }, [setValue])

  const { isLoadingRecoverPassword } = useSelector((state) => state.get("auth"))

  const classes = useStyles()

  return (
    <Grid>
      <Title>Forgot My Password</Title>
      <Subtitle>Create New Password</Subtitle>
      {!emailSended ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="newPassword"
            label="New Password"
            errors={errors}
            control={control}
            type="password"
          />
          <Input
            name="confirmPassword"
            label="Confirm Password"
            errors={errors}
            control={control}
            type="password"
          />
          {error ? <Error text={error} /> : null}
          <PrimaryButton
            className={classes.button}
            type="submit"
            isLoading={isLoadingRecoverPassword}
          >
            Change Password
          </PrimaryButton>
        </form>
      ) : (
        <Grid>
          <Typography className={classes.subtitle}>
            {`Your password has been successfully changed, you can now use it to login!`}
          </Typography>
          <PrimaryButton className={classes.button} onClick={goToLogin}>
            Go back to Login
          </PrimaryButton>
        </Grid>
      )}
    </Grid>
  )
}

export default RecoverPasswordForm
