import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { Grid, Grow } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import Button from "@components/UI/Button/Button"
import PrimaryButton from "@components/UI/Button/PrimaryButton"
import Input from "@components/UI/Input/Input"

import Error from "./Error"

import verifyUserByEmail from "@api/services/auth/verifyUserByEmail"
import loginResolver from "@api/validation/auth/loginResolver"
import verifyEmailResolver from "@api/validation/auth/verifyEmailResolver"

import { login } from "@redux/actions/authActions"

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 16,
  },
  forgotButton: {
    marginTop: 8,
  },
  form: {
    width: "100%",
  },
}))

function LoginForm(props) {
  const [isEmailFetched, setIsEmailFetched] = useState(false)
  const [error, setError] = useState(false)

  const onLoginError = () => {
    setError(true)
  }

  const onChange = () => {
    setError(false)
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: isEmailFetched ? loginResolver : verifyEmailResolver,
  })

  const dispatch = useDispatch()
  const history = useHistory()

  const onSubmit = async ({ email, password }) => {
    setError(false)
    if (isEmailFetched) {
      dispatch(login({ email, password }, onLoginError))
    } else {
      const isEmailVerified = await verifyUserByEmail(email)
      if (isEmailVerified) {
        setIsEmailFetched(true)
      } else {
        history.replace({ pathname: "/register", search: `?email=${email}` })
      }
    }
  }

  const goToForgotPassword = () => {
    const email = getValues("email")
    history.replace({ pathname: "/forgot-password", search: `?email=${email}` })
  }

  const { isLoadingLogin } = useSelector((state) => state.get("auth"))

  const classes = useStyles()

  return (
    <Grid container>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Input
          name="email"
          label="Email"
          errors={errors}
          control={control}
          onChange={onChange}
        />
        <Grow
          in={isEmailFetched}
          style={{ transformOrigin: "0 20 0" }}
          timeout={1000}
          unmountOnExit
        >
          <Grid>
            <Input
              name="password"
              label="Senha"
              type="password"
              errors={errors}
              control={control}
              onChange={onChange}
              autoFocus
            />
          </Grid>
        </Grow>

        {isEmailFetched ? (
          <Button className={classes.forgotButton} onClick={goToForgotPassword}>
            Forgot my password
          </Button>
        ) : null}
        {error ? <Error text="Invalid email or password. Try again." /> : null}
        <PrimaryButton
          className={classes.button}
          type="submit"
          isLoading={isLoadingLogin}
        >
          Next
        </PrimaryButton>
      </form>
    </Grid>
  )
}

export default LoginForm
