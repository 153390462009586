import React from "react"

import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Delete } from "@material-ui/icons"

import confirm from "@api/utils/confirm"

const useStyles = makeStyles((theme) => ({
  button: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    marginLeft: 16,
  },
}))

function DangerButton({ alertTitle, alertText, onClick, ...props }) {
  const classes = useStyles()

  const handleClick = () => {
    confirm(alertTitle, alertText, onClick)
  }

  return (
    <Button
      className={classes.button}
      {...props}
      variant="outlined"
      endIcon={<Delete />}
      onClick={handleClick}
    >
      {props.children}
    </Button>
  )
}

export default DangerButton
