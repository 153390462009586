import React from "react"
import { Controller } from "react-hook-form"

import classNames from "classnames"

import { Grid, Typography, TextField, MenuItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  select: {
    height: 48,
    fontSize: 16,
    marginTop: 12,
    marginBottom: 12,
    width: "100%",
    borderRadius: 6,
  },
  errorMessage: {
    fontSize: 13,
    width: "100%",
    marginLeft: 8,
    marginTop: 4,
    color: theme.palette.error.main,
  },
}))

export default function Select(props) {
  const classes = useStyles()

  const { options, control, name, errors, onChange, className, ...rest } = props

  const isWithError = () => {
    if (errors && errors[name]) {
      return true
    }
    return false
  }

  const getErrorMessage = () => {
    const { errors, name } = props
    return errors[name]?.message
  }

  const errorMessage = getErrorMessage()

  return (
    <Grid container justifyContent="center">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className={classNames(classes.select, className)}
            fullWidth
            select
            error={isWithError()}
            variant="outlined"
            onChange={(...params) => {
              field.onChange(...params)
              if (onChange) {
                onChange(...params)
              }
            }}
            value={field.value || ""}
            {...rest}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      {errorMessage ? (
        <Typography className={classes.errorMessage}>{errorMessage}</Typography>
      ) : null}
    </Grid>
  )
}
