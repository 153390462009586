import React from "react"

import { IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Close } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  button: {
    margin: 12,
  },
  icon: {
    fontSize: 25,
  },
}))

function CloseButton(props) {
  const classes = useStyles()

  return (
    <IconButton className={classes.button} {...props}>
      <Close className={classes.icon} {...props.iconProps} />
    </IconButton>
  )
}

export default CloseButton
