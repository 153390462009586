import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import AddButton from "@components/UI/Button/AddButton"
import PrimaryButton from "@components/UI/Button/PrimaryButton"
import Dialog from "@components/UI/Dialog"
import FileInput from "@components/UI/Input/FileInput"
import Input from "@components/UI/Input/Input"
import Select from "@components/UI/Input/Select"
import Switch from "@components/UI/Input/Switch"
import Tags from "@components/UI/Input/Tags"
import ErrorText from "@components/UI/Text/ErrorText"
import PageTitle from "@components/UI/Text/PageTitle"

import creationResolver from "@api/validation/video/creationResolver"
import creationWithLinkResolver from "@api/validation/video/creationWithLinkResolver"

import { createVideo } from "@redux/actions/videosActions"

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    height: "100%",
  },
  buttonContainer: {
    marginTop: 24,
  },
}))

const visibilities = [
  { value: "PUBLIC", label: "Public" },
  { value: "PRIVATE", label: "Private" },
]

function CreateVideoButton(props) {
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)

  const [withLink, setWithLink] = useState(false)
  const [file, setFile] = useState(null)
  const [progress, setProgress] = useState(0)
  const [uploading, setUploading] = useState(false)

  const classes = useStyles()

  const onClose = () => {
    setOpen(false)
  }

  const onClickOpen = () => {
    setOpen(true)
  }

  const onChangeWithLink = (newValue) => {
    setWithLink(newValue)
    setError(false)
  }

  const dispatch = useDispatch()

  const onCreateSuccess = () => {
    onClose()
  }

  const onProgress = (p) => {
    if (!uploading) {
      setUploading(true)
    }
    setProgress(p)
  }

  const onSubmit = (body) => {
    if (!withLink && !file) {
      setError(true)
    } else {
      dispatch(createVideo(body, withLink, file, onCreateSuccess, onProgress))
    }
  }

  const onChange = () => {
    if (error) setError(false)
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: withLink ? creationWithLinkResolver : creationResolver,
  })

  return (
    <>
      <AddButton onClick={onClickOpen}>Create new Video</AddButton>
      <Dialog open={open} onClose={onClose}>
        <form>
          <Grid container className={classes.content} justifyContent="center">
            <Grid item xs={12} sm={8} md={6}>
              <PageTitle>Create a Video</PageTitle>

              <Switch
                text="Upload video using link"
                checked={withLink}
                onChange={onChangeWithLink}
              />

              {withLink ? (
                <Input
                  name="input"
                  label="Input Link"
                  errors={errors}
                  control={control}
                  onChange={onChange}
                />
              ) : (
                <FileInput
                  onChange={setFile}
                  uploading={uploading}
                  progress={progress}
                />
              )}
              <Tags
                name="tags"
                label="Tags"
                errors={errors}
                control={control}
                onChange={onChange}
              />
              <Select
                name="visibility"
                label="Visibility"
                errors={errors}
                control={control}
                onChange={onChange}
                options={visibilities}
              />

              {error && <ErrorText>Video file is required</ErrorText>}

              {!uploading && (
                <Grid container justifyContent="center">
                  <PrimaryButton
                    onClick={handleSubmit(onSubmit)}
                    maxWidth={250}
                    className={classes.buttonContainer}
                  >
                    Send
                  </PrimaryButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </>
  )
}

export default CreateVideoButton
