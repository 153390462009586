import React from "react"

import { Button, Grid, Typography, Icon } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    width: "100%",
    transition: "all 0.3s",
    textTransform: "none",
    borderRadius: 0,
    "&:hover": {
      "& $label": {
        opacity: 1,
      },
      "& $icon": {
        opacity: 1,
      },
    },
  },
  label: {
    color: theme.palette.background.contrast,
    opacity: 0.7,
    fontSize: 15,
    transition: "all 0.3s",
    fontWeight: 500,
  },
  icon: {
    fontSize: 20,
    opacity: 0.7,
    color: theme.palette.grey[400],
  },
}))

function MenuItem(props) {
  const classes = useStyles()

  const doAction = () => {
    props.item.action()
  }

  return (
    <Button className={classes.root} onClick={doAction}>
      <Grid container justifyContent="space-between">
        <Typography className={classes.label}>{props.item.label}</Typography>
        <Icon className={classes.icon}>{props.item.icon}</Icon>
      </Grid>
    </Button>
  )
}

export default MenuItem
