import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

function useRedirectIfAuthenticated(props) {
  const history = useHistory()
  const { isAuthenticated } = useSelector((state) => state.get("auth"))

  useEffect(() => {
    if (isAuthenticated) {
      history.replace("/app")
    }
  }, [history, isAuthenticated])

  return
}

export default useRedirectIfAuthenticated
