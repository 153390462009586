import requestApi from "@api/request/requestApi"
import getErrorMessage from "@api/utils/getErrorMessage"

import c from "@redux/constants/authConstants"

const loginRequest = (user) => {
  return {
    type: c.LOGIN_REQUEST,
    user,
  }
}

const loginSuccess = (user) => {
  return {
    type: c.LOGIN_SUCCESS,
    user,
  }
}

const loginFailure = (error) => {
  return {
    type: c.LOGIN_FAILURE,
    error: error.status || "Email ou senha incorretos. Tente novamente.",
  }
}

export const login = (user, onError) => {
  return (dispatch) => {
    dispatch(loginRequest())

    const body = {
      email: user.email,
      password: user.password,
      role: "customer",
    }

    requestApi({ url: `/users/login`, method: "post", body })
      .then((response) => {
        localStorage.setItem("access_token", response.data.token)
        dispatch(loginSuccess(response.data))
      })
      .catch((err) => {
        dispatch(loginFailure(err.response))
        if (onError) {
          onError()
        }
      })
  }
}

const logoutSuccess = () => {
  return {
    type: c.LOGOUT_SUCCESS,
  }
}

export const logoutUser = () => {
  return (dispatch) => {
    localStorage.removeItem("access_token")
    dispatch(logoutSuccess())
  }
}

const registerRequest = (user) => {
  return {
    type: c.REGISTER_REQUEST,
    user,
  }
}

const registerSuccess = (user) => {
  return {
    type: c.REGISTER_SUCCESS,
    user,
  }
}

const registerFailure = (error) => {
  return {
    type: c.REGISTER_FAILURE,
    error: error.status || "Problema ao criar conta",
  }
}

export const register = (data, onSuccess, onError) => {
  return (dispatch) => {
    dispatch(registerRequest())

    const body = data

    requestApi({ url: `/users`, method: "post", body })
      .then((response) => {
        localStorage.setItem("access_token", response.data.token)
        if (onSuccess) {
          onSuccess(response.data)
        }
        dispatch(registerSuccess(response.data))
      })
      .catch((err) => {
        dispatch(registerFailure(err.response))
        if (onError) {
          onError(getErrorMessage(err))
        }
      })
  }
}

const forgotPasswordRequest = (user) => {
  return {
    type: c.FORGOT_PASSWORD_REQUEST,
    user,
  }
}

const forgotPasswordSuccess = (user) => {
  return {
    type: c.FORGOT_PASSWORD_SUCCESS,
    user,
  }
}

const forgotPasswordFailure = (error) => {
  return {
    type: c.FORGOT_PASSWORD_FAILURE,
    error: error.status || "Não foi possível recuperar sua senha.",
  }
}

export const forgotPassword = (data, onSuccess, onError) => {
  return (dispatch) => {
    dispatch(forgotPasswordRequest())

    const body = data

    requestApi({
      url: `/users/forgot-password`,
      method: "post",
      body,
    })
      .then((response) => {
        dispatch(forgotPasswordSuccess(response.data))
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch((err) => {
        dispatch(forgotPasswordFailure(err.response))
        if (onError) {
          onError(getErrorMessage(err))
        }
      })
  }
}

const recoverPasswordRequest = (user) => {
  return {
    type: c.RECOVER_PASSWORD_REQUEST,
    user,
  }
}

const recoverPasswordSuccess = (user) => {
  return {
    type: c.RECOVER_PASSWORD_SUCCESS,
    user,
  }
}

const recoverPasswordFailure = (error) => {
  return {
    type: c.RECOVER_PASSWORD_FAILURE,
    error: error.status || "Não foi possível recuperar sua senha.",
  }
}

export const recoverPassword = (data, onSuccess, onError) => {
  return (dispatch) => {
    dispatch(recoverPasswordRequest())

    const body = data

    requestApi({
      url: `/authentication/recover-password`,
      method: "post",
      body,
    })
      .then((response) => {
        dispatch(recoverPasswordSuccess(response.data))
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch((err) => {
        dispatch(recoverPasswordFailure(err.response))
        if (onError) {
          onError(getErrorMessage(err))
        }
      })
  }
}
