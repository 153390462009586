import requestApi from "@api/request/requestApi"

const verifyUserByEmail = async (email) => {
  try {
    await requestApi({
      url: `/users/verify/${email}`,
    })

    return true
  } catch (e) {
    return false
  }
}

export default verifyUserByEmail
