module.exports = {
  darkBackground: "#252525",
  black: "#1d1d1d",
  textContrast: "#eee",
  primaryMain: "#673AB7",
  primaryDark: "#512DA8",
  primaryLight: "#D1C4E9",
  secondaryMain: "#009688",
  secondaryDark: "#00796B",
  secondaryLight: "#B2DFDB",
}
