import React from "react"
import { useHistory, useRouteMatch } from "react-router-dom"

import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import SimpleTable from "@components/Table/SimpleTable"
import Loading from "@components/UI/Loading"

import formatTableData from "@api/format/formatTableData"
import tableHeader from "@api/lives/tableHeader"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 48,
    minHeight: "100vh",
  },
  notFound: {
    color: theme.palette.text.secondary,
    margin: 36,
    textAlign: "center,",
  },
}))

function LivesList({ isFetching, lives }) {
  // const [page, setPage] = useState(1)
  // const [filter, setFilter] = useState("")

  const classes = useStyles()

  const history = useHistory()
  const match = useRouteMatch()

  const onClickLive = (id) => {
    history.push(`${match.url}/${id}`)
  }

  // const onChangePage = (p) => {
  //   setPage(p)
  //   fetchLives(p, filter)
  // }

  // const changeFilter = (f) => {
  //   setFilter(f)
  //   setPage(1)
  //   fetchLives(1, f)
  // }

  return (
    <Grid className={classes.root}>
      {/* <SearchInput onSearch={changeFilter} placeholder="Search for Tags" /> */}
      {isFetching ? (
        <Loading />
      ) : lives && lives.length ? (
        <>
          <SimpleTable
            header={tableHeader}
            data={formatTableData(lives)}
            onClickRow={onClickLive}
          />
          {/* <CustomPagination
            pageCount={videosPageCount}
            onChange={onChangePage}
            page={page}
          /> */}
        </>
      ) : (
        <Grid container justifyContent="center">
          <Typography className={classes.notFound}>
            No live found with this query
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default LivesList
