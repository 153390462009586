import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import PrimaryButton from "@components/UI/Button/PrimaryButton"
import Input from "@components/UI/Input/Input"

import Error from "./Error"
import Title from "./Title"

import getUrlParams from "@api/utils/getUrlParams"
import registerResolver from "@api/validation/auth/registerResolver"

import { register } from "@redux/actions/authActions"

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 16,
  },
  forgotButton: {
    marginTop: 8,
  },
  leftInput: {
    [theme.breakpoints.up("sm")]: {
      marginRight: 16,
    },
  },
}))

function RegisterForm(props) {
  const [defaultEmail, setDefaultEmail] = useState("")
  const [error, setError] = useState("")

  const onRegisterError = (error) => {
    setError(error)
  }

  const dispatch = useDispatch()

  const history = useHistory()
  const onRegisterSuccess = (data) => {
    history.replace(
      `/app?tokenId=${data.tokenId}&tokenSecret=${data.tokenSecret}`
    )
  }

  const onSubmit = async (data) => {
    setError("")
    dispatch(register(data, onRegisterSuccess, onRegisterError))
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: registerResolver,
  })

  useEffect(() => {
    const params = getUrlParams()
    const defaultEmail = params?.email
    defaultEmail && setValue("email", defaultEmail)
    setDefaultEmail(defaultEmail)
  }, [setValue])

  const { isLoadingRegister } = useSelector((state) => state.get("auth"))

  const classes = useStyles()

  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        {defaultEmail ? (
          <Title>New around here? Create your account</Title>
        ) : null}
        <Input name="email" label="Email" errors={errors} control={control} />
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Input
              name="password"
              label="Password"
              errors={errors}
              control={control}
              type="password"
              className={classes.leftInput}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="confirmPassword"
              label="Confirm Password"
              errors={errors}
              control={control}
              type="password"
            />
          </Grid>
        </Grid>

        {error ? <Error text={error} /> : null}
        <PrimaryButton
          className={classes.button}
          type="submit"
          isLoading={isLoadingRegister}
        >
          Create an account
        </PrimaryButton>
      </form>
    </Grid>
  )
}

export default RegisterForm
