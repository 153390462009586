import requestApi from "@api/request/requestApi"
import alert from "@api/utils/alert"

const resetTokens = async () => {
  try {
    const resp = await requestApi({
      method: "patch",
      url: `/users/reset-tokens`,
    })
    alert("success", "Tokens successfully reset")
    return resp.data
  } catch (e) {
    alert("error", "Unable to reset tokens")
    return null
  }
}

export default resetTokens
