import React from "react"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import PageTitle from "@components/UI/Text/PageTitle"
import CreateVideoButton from "@components/Videos/CreateVideoButton"
import VideosList from "@components/Videos/VideosList"

const useStyles = makeStyles((theme) => ({
  root: {},
}))

function Videos(props) {
  const classes = useStyles()

  return (
    <Grid className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <PageTitle>Videos</PageTitle>
        </Grid>
        <Grid item>
          <CreateVideoButton />
        </Grid>
      </Grid>
      <VideosList />
    </Grid>
  )
}

export default Videos
