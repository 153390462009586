import React from "react"

import classNames from "classnames"

import { Input } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  field: {
    margin: "12px 0",
    width: "100%",
    height: 48,
    borderColor: theme.palette.action.disabled,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 6,
    paddingLeft: 12,
    paddingRight: 12,
    display: "flex",
    alignItems: "center",
  },
}))

function SimpleInput({ className, ...props }) {
  const classes = useStyles()

  return <Input className={classNames(className, classes.field)} {...props} />
}

export default SimpleInput
