import React, { useState, useEffect } from "react"

import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import PrimaryButton from "@components/UI/Button/PrimaryButton"
import Input from "@components/UI/Input/SimpleInput"

import resetTokens from "@api/services/tokens/resetTokens"
import confirm from "@api/utils/confirm"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 48,
  },
  column: {
    width: "100%",
  },
  buttonContainer: {
    marginTop: 24,
  },
  description: {
    textAlign: "center",
    fontWeight: "bold",
    margin: "16px 0",
  },
}))

function TokensForm(props) {
  const [tokenId, setTokenId] = useState("")
  const [tokenSecret, setTokenSecret] = useState("")

  useEffect(() => {
    setTokenId(props.tokenId)
  }, [props.tokenId])

  const onReset = async () => {
    const tokens = await resetTokens()
    if (tokens) {
      setTokenId(tokens.tokenId)
      setTokenSecret(tokens.tokenSecret)
    }
  }

  const handleReset = () => {
    confirm(
      "Are you shure?",
      "If you reset your tokens the old ones won't work",
      onReset
    )
  }

  const classes = useStyles()

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={11} sm={8} md={6} className={classes.column}>
        <Typography className={classes.label}>Token ID</Typography>
        <Input disabled disableUnderline onChange={() => {}} value={tokenId} />
      </Grid>
      <Grid item xs={11} sm={8} md={6} className={classes.column}>
        <Typography className={classes.label}>Token Secret</Typography>
        <Input
          disabled
          disableUnderline
          onChange={() => {}}
          value={tokenSecret}
        />
      </Grid>

      {tokenId && tokenSecret && (
        <Typography className={classes.description}>
          You must keep these tokens, they can no longer be retrieved
        </Typography>
      )}
      <Grid
        container
        justifyContent="center"
        className={classes.buttonContainer}
      >
        <PrimaryButton onClick={handleReset} paddingHorizontal={24}>
          {tokenId ? "Reset Tokens" : "Generate Tokens"}
        </PrimaryButton>
      </Grid>
    </Grid>
  )
}

export default TokensForm
