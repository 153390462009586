import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import LeftMenu from "@components/LeftMenu"

import Live from "./Live"
import Lives from "./Lives"
import Tokens from "./Tokens"
import Video from "./Video"
import Videos from "./Videos"

import useRedirectToLogin from "@hooks/auth/useRedirectToLogin"

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    overflowY: "auto",
    flex: 1,
    padding: 48,
  },
}))

function Dashboard(props) {
  useRedirectToLogin()

  const classes = useStyles()
  const match = useRouteMatch()

  return (
    <Grid>
      <Grid container>
        <Grid item>
          <LeftMenu />
        </Grid>
        <Grid item className={classes.content}>
          <Switch>
            <Route exact path={`${match.path}/videos`} component={Videos} />
            <Route exact path={`${match.path}/videos/:id`} component={Video} />
            <Route exact path={`${match.path}/lives`} component={Lives} />
            <Route exact path={`${match.path}/lives/:id`} component={Live} />
            <Route exact path={`${match.path}/tokens`} component={Tokens} />
            <Redirect to={`${match.path}/videos`} />
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Dashboard
