import c from "@redux/constants/videosConstants"

const initialState = {
  error: "",
  isLoadingVideos: false,
  videos: [],
  videosPageCount: 1,
  url: "",
}

export default function videos(state = initialState, action) {
  switch (action.type) {
    case c.GET_VIDEOS_REQUEST:
      return {
        ...state,
        isLoadingVideos: true,
      }

    case c.GET_VIDEOS_SUCCESS: {
      return {
        ...state,
        isLoadingVideos: false,
        videos: action.payload.videos,
        videosPageCount: action.payload.pageCount,
        error: false,
      }
    }

    case c.GET_VIDEOS_FAILURE: {
      return {
        ...state,
        isLoadingVideos: false,
        error: action.error,
      }
    }

    case c.GET_VIDEO_REQUEST:
      return {
        ...state,
        isLoadingVideo: true,
        url: "",
      }

    case c.GET_VIDEO_SUCCESS: {
      return {
        ...state,
        isLoadingVideo: false,
        video: action.payload,
        error: false,
      }
    }

    case c.GET_VIDEO_FAILURE: {
      return {
        ...state,
        isLoadingVideo: false,
        error: action.error,
      }
    }

    case c.GET_VIDEO_URL_SUCCESS: {
      return {
        ...state,
        url: action.payload,
        error: false,
      }
    }

    case c.CREATE_VIDEO_REQUEST:
      return {
        ...state,
        isCreatingVideo: true,
      }

    case c.CREATE_VIDEO_SUCCESS: {
      return {
        ...state,
        isCreatingVideo: false,
        video: action.payload,
        error: false,
      }
    }

    case c.CREATE_VIDEO_FAILURE: {
      return {
        ...state,
        isCreatingVideo: false,
        error: action.error,
      }
    }

    default:
      return state
  }
}
