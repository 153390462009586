import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import Grid from "@material-ui/core/Grid"

import App from "@components/Routes/RoutesGeneral"

import "./styles/App.css"

import store from "@redux/configureStore"

function MyApp() {
  return (
    <Grid className="App">
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </Grid>
  )
}

export default MyApp
