import React from "react"

import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Edit } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: 16,
  },
}))

function EditButton(props) {
  const classes = useStyles()
  return (
    <Button
      className={classes.button}
      color="secondary"
      endIcon={<Edit />}
      {...props}
    >
      {props.children}
    </Button>
  )
}

export default EditButton
