import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import EditButton from "@components/UI/Button/EditButton"
import PrimaryButton from "@components/UI/Button/PrimaryButton"
import Dialog from "@components/UI/Dialog"
import FileInput from "@components/UI/Input/FileInput"
import Input from "@components/UI/Input/Input"
import Switch from "@components/UI/Input/Switch"
import ErrorText from "@components/UI/Text/ErrorText"
import PageTitle from "@components/UI/Text/PageTitle"

import requestApi from "@api/request/requestApi"
import editFileResolver from "@api/validation/video/editFileResolver"

import { alert } from "@redux/actions/alertActions"
import { uploadVideo, changeInput } from "@redux/actions/videosActions"

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    height: "100%",
  },
  buttonContainer: {
    marginTop: 24,
  },
}))

function EditVideoFileButton(props) {
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)

  const [withLink, setWithLink] = useState(false)
  const [file, setFile] = useState(null)
  const [progress, setProgress] = useState(0)
  const [uploading, setUploading] = useState(false)

  const classes = useStyles()

  const onClose = () => {
    setOpen(false)
  }

  const onClickOpen = () => {
    setOpen(true)
  }

  const onChangeWithLink = (newValue) => {
    setError(false)
    setWithLink(newValue)
  }

  const onChange = () => {
    setError(false)
  }

  const onChangeFile = (file) => {
    setFile(file)
    setError(false)
  }

  const dispatch = useDispatch()

  const onEditSuccess = () => {
    dispatch(alert("success", "Video file edited successfully"))
    onClose()
  }

  const onProgress = (p) => {
    if (!uploading) {
      setUploading(true)
    }
    setProgress(p)
  }

  const { video } = useSelector((state) => state.get("videos"))

  const onSubmit = async (body) => {
    if (withLink) {
      dispatch(changeInput(video?._id, body, onEditSuccess))
    } else {
      const { data } = await requestApi({
        method: "get",
        url: `/videos/${video._id}/upload`,
      })

      if (file) {
        dispatch(
          uploadVideo(data?.url, video._id, file, onEditSuccess, onProgress)
        )
      } else {
        setError(true)
      }
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: withLink && editFileResolver,
  })

  return (
    <>
      <EditButton onClick={onClickOpen}>Edit Video File</EditButton>
      <Dialog open={open} onClose={onClose}>
        <form>
          <Grid container className={classes.content} justifyContent="center">
            <Grid item xs={12} sm={8} md={6}>
              <PageTitle>Edit Video File</PageTitle>

              <Switch
                text="Upload video using link"
                checked={withLink}
                onChange={onChangeWithLink}
              />

              {withLink ? (
                <Input
                  name="input"
                  label="Input Link"
                  errors={errors}
                  control={control}
                  onChange={onChange}
                />
              ) : (
                <FileInput
                  onChange={onChangeFile}
                  uploading={uploading}
                  progress={progress}
                />
              )}

              {error && <ErrorText>Video File is required</ErrorText>}

              {!uploading && (
                <Grid container justifyContent="center">
                  <PrimaryButton
                    onClick={handleSubmit(onSubmit)}
                    maxWidth={250}
                    className={classes.buttonContainer}
                  >
                    Send
                  </PrimaryButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </>
  )
}

export default EditVideoFileButton
