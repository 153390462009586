import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import AddButton from "@components/UI/Button/AddButton"
import PrimaryButton from "@components/UI/Button/PrimaryButton"
import Dialog from "@components/UI/Dialog"
import Select from "@components/UI/Input/Select"
import Tags from "@components/UI/Input/Tags"
import PageTitle from "@components/UI/Text/PageTitle"

import { RequestSDK } from "@api/request"
import creationResolver from "@api/validation/video/creationResolver"

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    height: "100%",
  },
  buttonContainer: {
    marginTop: 24,
  },
}))

const visibilities = [
  { value: "PUBLIC", label: "Public" },
  { value: "PRIVATE", label: "Private" },
]

function CreateLiveButton(props) {
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)

  const classes = useStyles()

  const onClose = () => {
    setOpen(false)
  }

  const onClickOpen = () => {
    setOpen(true)
  }

  const onSubmit = async (body) => {
    await RequestSDK.lives.createLive(body)
    onClose()
    props.onCreateSuccess()
  }

  const onChange = () => {
    if (error) setError(false)
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: creationResolver,
  })

  return (
    <>
      <AddButton onClick={onClickOpen}>Create new Live</AddButton>
      <Dialog open={open} onClose={onClose}>
        <form>
          <Grid container className={classes.content} justifyContent="center">
            <Grid item xs={12} sm={8} md={6}>
              <PageTitle>Create a Live</PageTitle>

              <Tags
                name="tags"
                label="Tags"
                errors={errors}
                control={control}
                onChange={onChange}
              />
              <Select
                name="visibility"
                label="Visibility"
                errors={errors}
                control={control}
                onChange={onChange}
                options={visibilities}
              />

              <Grid container justifyContent="center">
                <PrimaryButton
                  onClick={handleSubmit(onSubmit)}
                  maxWidth={250}
                  className={classes.buttonContainer}
                >
                  Send
                </PrimaryButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </>
  )
}

export default CreateLiveButton
