import requestApi from "@api/request/requestApi"

const getMe = async () => {
  try {
    const resp = await requestApi({
      method: "get",
      url: `/users/me`,
    })
    return resp.data
  } catch (e) {
    return null
  }
}

export default getMe
