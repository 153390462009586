import { logoutUser } from "@redux/actions/authActions"
import store from "@redux/configureStore"

const getMenuItems = (history, prefix) => {
  const menuItems = [
    {
      label: "Videos",
      path: "/videos",
    },
    {
      label: "Lives",
      path: "/lives",
    },
  ]

  const endMenuItems = [
    {
      label: "Manage Tokens",
      action: () => {
        history.push(`${prefix}/tokens`)
      },
      icon: "lock",
    },
    {
      label: "Log out",
      action: () => {
        store.dispatch(logoutUser())
      },
      icon: "logout",
    },
  ]

  return {
    menuItems,
    endMenuItems,
  }
}

export default getMenuItems
