import { LivesRequests } from "./LivesRequests"
import requestApi from "./requestApi"

class RequestsSDKClass {
  constructor() {
    this.requestApi = requestApi
    this.lives = new LivesRequests(this.requestApi)
  }

  static getInstance(config) {
    if (!this.instance) {
      this.instance = new RequestsSDKClass(config)
    }
    return this.instance
  }
}

export const RequestSDK = RequestsSDKClass.getInstance()
