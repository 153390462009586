import Tags from "@components/UI/Text/Tags"

import formatDate from "@api/format/formatDate"

const formatTableData = (data) => {
  return data.map((l) => {
    const line = Object.assign({}, l)
    line.tags = <Tags tags={l.tags} />
    line.duration = l.duration ? Math.ceil(l.duration / 60) + " min" : ""

    const createdAtDate = new Date(l.createdAt)
    line.createdAt = formatDate(createdAtDate, "HH:mm dd/MM/yyyy")

    return line
  })
}

export default formatTableData
