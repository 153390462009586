import { yupResolver } from "@hookform/resolvers/yup"

import * as yup from "yup"

import { validateEmail } from "."

const schema = yup.object().shape({
  email: validateEmail(),
})

export default yupResolver(schema)
