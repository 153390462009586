import { yupResolver } from "@hookform/resolvers/yup"

import * as yup from "yup"

import { validateEmail, validatePassword } from "./"

const schema = yup.object().shape({
  email: validateEmail(),
  password: validatePassword(),
})

export default yupResolver(schema)
