import React from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  Grid,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { FileCopy } from "@material-ui/icons"

import Player from "@components/Player"
import Input from "@components/UI/Input/SimpleInput"

import { getVideoUrl } from "@redux/actions/videosActions"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  button: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.text.contrastText,
    padding: "8px 24px",
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  fieldContent: {
    flex: 1,
  },
  value: {
    color: theme.palette.text.secondary,
  },
  description: {
    marginTop: 24,
    color: theme.palette.text.secondary,
  },
  player: {
    marginTop: 24,
  },
}))

function GetVideoUrl(props) {
  const dispatch = useDispatch()

  const { video, url } = useSelector((state) => state.get("videos"))

  const handleClick = () => {
    dispatch(getVideoUrl(video?._id))
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(url)
  }

  const classes = useStyles()
  return (
    <Grid container>
      {video?.visibility === "PRIVATE" && (
        <Grid container>
          <Typography className={classes.description}>
            This video is private, it means the URL expires. Each time the user
            wants to access, it is necessary to search again.
          </Typography>
        </Grid>
      )}
      <Grid container className={classes.root} alingItems="center">
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleClick}
        >
          Get URL
        </Button>
        <Grid item className={classes.fieldContent}>
          <Input disabled disableUnderline value={url} />
        </Grid>
        <Tooltip title="Copy URL">
          <IconButton onClick={handleCopy}>
            <FileCopy />
          </IconButton>
        </Tooltip>
      </Grid>
      {url && (
        <Grid container className={classes.player}>
          <Player url={url} />
        </Grid>
      )}
    </Grid>
  )
}

export default GetVideoUrl
