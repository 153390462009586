import React, { useState, useEffect } from "react"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import TokensForm from "@components/Tokens/TokensForm"
import PageTitle from "@components/UI/Text/PageTitle"

import getMe from "@api/services/users/getMe"

const useStyles = makeStyles((theme) => ({}))

function Tokens(props) {
  const [tokenId, setTokenId] = useState("")

  const fetchUser = async () => {
    const { tokenId: t } = await getMe()
    setTokenId(t)
  }

  useEffect(() => {
    fetchUser()
  }, [])

  const classes = useStyles()

  return (
    <Grid className={classes.root}>
      <PageTitle>Manage Tokens</PageTitle>
      <TokensForm tokenId={tokenId} />
    </Grid>
  )
}

export default Tokens
