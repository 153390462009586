import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import EditButton from "@components/UI/Button/EditButton"
import PrimaryButton from "@components/UI/Button/PrimaryButton"
import Dialog from "@components/UI/Dialog"
import Tags from "@components/UI/Input/Tags"
import PageTitle from "@components/UI/Text/PageTitle"

import editMetadataResolver from "@api/validation/video/editMetadataResolver"

import { editVideo } from "@redux/actions/videosActions"

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    height: "100%",
  },
  buttonContainer: {
    marginTop: 24,
  },
}))

function EditMetadataButton(props) {
  const [open, setOpen] = useState(false)

  const { video } = useSelector((state) => state.get("videos"))

  const classes = useStyles()

  const onClose = () => {
    setOpen(false)
  }

  const onClickOpen = () => {
    setOpen(true)
  }

  const dispatch = useDispatch()

  const onEditSuccess = () => {
    onClose()
  }

  const onSubmit = (body) => {
    dispatch(editVideo(video?._id, body, onEditSuccess))
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: editMetadataResolver,
  })

  useEffect(() => {
    reset({ ...video })
  }, [video, reset])

  return (
    <>
      <EditButton onClick={onClickOpen}>Edit</EditButton>
      <Dialog open={open} onClose={onClose}>
        <Grid container className={classes.content} justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            <PageTitle>Edit Video Metadata</PageTitle>

            <Tags name="tags" label="Tags" errors={errors} control={control} />

            <Grid container justifyContent="center">
              <PrimaryButton
                onClick={handleSubmit(onSubmit)}
                maxWidth={250}
                className={classes.buttonContainer}
              >
                Send
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default EditMetadataButton
