import React from "react"

import { Grid, CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 48,
  },
}))

function Loading(props) {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <CircularProgress />
    </Grid>
  )
}

export default Loading
