import React from "react"

import { Grid, Dialog } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import CloseButton from "@components/UI/Button/CloseButton"

const useStyles = makeStyles((theme) => ({}))

function CustomDialog(props) {
  const classes = useStyles()

  return (
    <Dialog
      className={classes.root}
      open={props.open}
      onClose={props.onClose}
      fullScreen
    >
      <Grid container justifyContent="flex-end">
        <CloseButton onClick={props.onClose} />
      </Grid>
      {props.children}
    </Dialog>
  )
}

export default CustomDialog
