const tableHeader = {
  _id: "ID",
  status: "Status",
  tags: "Tags",
  duration: "Duration",
  visibility: "Visibility",
  createdAt: "Created",
}

export default tableHeader
