import React, { useState, useRef } from "react"

import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import SecondaryButton from "../Button/SecondaryButton"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  input: {
    display: "none",
  },
  textField: {
    height: 42,
    fontSize: 16,
    width: "100%",
    borderShadow: "none",
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    paddingLeft: 12,
    borderWidth: 1,
  },
  column: {
    minWidth: 250,
  },
  button: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  title: {
    fontWeight: "bold",
    marginBottom: 16,
  },
  outBar: {
    width: 400,
    height: 40,
    borderColor: theme.palette.action.disabled,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 6,
    position: "relative",
  },
  inBar: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    height: "100%",
    backgroundColor: theme.palette.secondary.main,
    transition: "all 0.3s",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  progressText: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
  doneText: {
    textAlign: "center",
    color: theme.palette.success.main,
  },
}))

function FileInput(props) {
  const [file, setFile] = useState(null)
  const classes = useStyles()
  const inputRef = useRef()

  const onChange = (e) => {
    const f = e.target.files[0]
    setFile(f)
    props.onChange(f)
  }

  const onClick = () => {
    inputRef?.current?.click()
  }

  const progress = props.progress ? Math.floor(props.progress * 100) : 0

  const done = progress === 100

  return (
    <Grid container className={classes.root}>
      {props.uploading ? (
        <Grid container alignItems="center" direction="column">
          {file && !done && (
            <Typography className={classes.title}>
              Uploading {file.name}, please do not leave this page
            </Typography>
          )}
          <Grid className={classes.outBar}>
            <Grid className={classes.inBar} style={{ width: `${progress}%` }} />
          </Grid>
          <Typography className={classes.progressText}>{progress}%</Typography>
          {done && (
            <Typography className={classes.doneText}>
              Video uploaded successfully, you can close this page now
            </Typography>
          )}
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <input
            type="file"
            className={classes.input}
            onChange={onChange}
            ref={inputRef}
          />

          <Grid item className={classes.column}>
            <input
              disabled
              placeholder="Select File"
              value={file ? file.name : ""}
              className={classes.textField}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <SecondaryButton
              className={classes.button}
              onClick={onClick}
              disableElevation
            >
              Search Video File
            </SecondaryButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default FileInput
