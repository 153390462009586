import axios from "axios"

import formatVideoCreationBody from "@api/format/video/formatVideoCreationBody"
import requestApi from "@api/request/requestApi"
import getErrorMessage from "@api/utils/getErrorMessage"

import { alert } from "@redux/actions/alertActions"
import c from "@redux/constants/videosConstants"

const getVideosRequest = () => {
  return {
    type: c.GET_VIDEOS_REQUEST,
  }
}

const getVideosSuccess = (payload) => {
  return {
    type: c.GET_VIDEOS_SUCCESS,
    payload,
  }
}

const getVideosFailure = () => {
  return {
    type: c.GET_VIDEOS_FAILURE,
  }
}

export const getVideos = (page, filter) => {
  return (dispatch) => {
    dispatch(getVideosRequest())
    requestApi({
      url: `/videos`,
      params: { q: filter || undefined, page: page - 1, limit: 5 },
    })
      .then((response) => {
        dispatch(getVideosSuccess(response.data))
      })
      .catch((err) => {
        dispatch(getVideosFailure(getErrorMessage(err)))
      })
  }
}

const getVideoRequest = () => {
  return {
    type: c.GET_VIDEO_REQUEST,
  }
}

const getVideoSuccess = (payload) => {
  return {
    type: c.GET_VIDEO_SUCCESS,
    payload,
  }
}

const getVideoFailure = () => {
  return {
    type: c.GET_VIDEO_FAILURE,
  }
}

export const getVideo = (id) => {
  return (dispatch) => {
    dispatch(getVideoRequest())
    requestApi({
      url: `/videos/${id}`,
    })
      .then((response) => {
        dispatch(getVideoSuccess(response.data))
      })
      .catch((err) => {
        dispatch(getVideoFailure(getErrorMessage(err)))
      })
  }
}

const getVideoUrlSuccess = (payload) => {
  return {
    type: c.GET_VIDEO_URL_SUCCESS,
    payload,
  }
}

export const getVideoUrl = (id) => {
  return (dispatch) => {
    requestApi({
      url: `/videos/${id}/stream`,
    })
      .then((response) => {
        dispatch(getVideoUrlSuccess(response.data.url))
      })
      .catch((err) => {
        console.log(getErrorMessage(err))
      })
  }
}

const createVideoRequest = () => {
  return {
    type: c.CREATE_VIDEO_REQUEST,
  }
}

const createVideoSuccess = (payload) => {
  return {
    type: c.CREATE_VIDEO_SUCCESS,
    payload,
  }
}

const createVideoFailure = () => {
  return {
    type: c.CREATE_VIDEO_FAILURE,
  }
}

export const changeInput = (id, body, onSuccess) => {
  return (dispatch) => {
    requestApi({
      url: `/videos/${id}/input`,
      method: "patch",
      body,
    })
      .then(() => {
        onSuccess()
        dispatch(getVideo(id))
      })
      .catch((e) => {
        console.log(getErrorMessage(e))
      })
  }
}

export const uploadVideo = (url, id, file, onSuccess, onProgress) => {
  return (dispatch) => {
    const formData = new FormData()
    formData.append("video", file)
    axios
      .post(url, formData, {
        onUploadProgress: (e) => onProgress(e.loaded / e.total),
      })
      .then(() => {
        onSuccess()
        dispatch(getVideo(id))
      })
      .catch((e) => {
        console.log(getErrorMessage(e))
      })
  }
}

export const createVideo = (
  body,
  withLink,
  file,
  onCreateSuccess,
  onProgress
) => {
  return (dispatch) => {
    dispatch(createVideoRequest())
    requestApi({
      url: `/videos`,
      method: "post",
      body: formatVideoCreationBody(body, withLink),
    })
      .then((response) => {
        dispatch(createVideoSuccess(response.data))
        if (!withLink) {
          const onUploadSuccess = () => {
            onCreateSuccess()
            dispatch(getVideos(0))
            dispatch(
              alert("success", "Video created and uploaded successfully")
            )
          }
          dispatch(
            uploadVideo(
              response.data.uploadUrl,
              response.data._id,
              file,
              onUploadSuccess,
              onProgress
            )
          )
        } else {
          onCreateSuccess()
          dispatch(getVideos(0))
          dispatch(alert("success", "Video created successfully"))
        }
      })
      .catch((err) => {
        const errorMessage = getErrorMessage(err)
        dispatch(createVideoFailure(errorMessage))
        dispatch(alert("error", errorMessage))
      })
  }
}

export const editVideo = (id, body, onSuccess) => {
  return (dispatch) => {
    requestApi({
      url: `/videos/${id}`,
      method: "put",
      body,
    })
      .then((response) => {
        dispatch(getVideoSuccess(response.data))
        dispatch(alert("success", "Video edited successfully"))
        onSuccess()
      })
      .catch((err) => {
        console.log(getErrorMessage(err))
      })
  }
}

export const deleteVideo = (id, onSuccess) => {
  return (dispatch) => {
    requestApi({
      url: `/videos/${id}`,
      method: "delete",
    })
      .then(() => {
        onSuccess()
        dispatch(alert("success", "Video deleted successfully"))
      })
      .catch((e) => {
        console.log(getErrorMessage(e))
      })
  }
}
