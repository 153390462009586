import React from "react"
import { useHistory } from "react-router-dom"

import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import ForgotPasswordForm from "@components/Auth/ForgotPasswordForm"
import Logo from "@components/Auth/Logo"
import BackButton from "@components/UI/Button/BackButton"

import useRedirectIfAuthenticated from "@hooks/auth/useRedirectIfAuthenticated"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    maxWidth: 1920,
  },
  button: {
    marginTop: 16,
  },
  content: {
    padding: "16px 0",
  },
}))

function ForgotPassword(props) {
  useRedirectIfAuthenticated()

  const history = useHistory()

  const goToLogin = () => {
    history.replace("/login")
  }

  const classes = useStyles()

  return (
    <Grid container justifyContent="center">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={11} sm={4} className={classes.content}>
          <BackButton label="Login" onClick={goToLogin} />
          <Logo />
          <ForgotPasswordForm />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ForgotPassword
