import React from "react"

import { Add } from "@material-ui/icons"

import PrimaryButton from "./PrimaryButton"

function AddButton(props) {
  return (
    <PrimaryButton endIcon={<Add />} {...props}>
      {props.children}
    </PrimaryButton>
  )
}

export default AddButton
